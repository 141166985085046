// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function App$default(props) {
  return React.createElement("div", {
              className: "text-3xl underline"
            }, "Hello World");
}

var $$default = App$default;

export {
  $$default ,
  $$default as default,
}
/* react Not a pure module */
